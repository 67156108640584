.entry,
.entry__img__holder {
  position: relative;
  overflow: hidden;
}

.card,
.entry,
.thumb__container {
  overflow: hidden;
}

.entry {
  margin-bottom: 30px;
}

.entry__img__holder {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-radius: 6px;
}

.entry__excerpt,
.entry__meta + .entry__meta,
.entry__meta__category__label + .entry__meta {
  margin-top: 16px;
}

.entry__header_light p {
  color: #acacac !important;
}

.entry__header_light small {
  color: #fff !important;
}

.entry__excerpt p {
  margin-bottom: 0;
}

.entry__meta {
  padding-top: 15px;
}

.entry__meta li {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #999;
}

.entry__meta li:after {
  content: "-";
  display: inline-block;
  margin: 0 3px;
}

.entry__meta li.entry__meta-views:after {
  content: "";
  margin: 0 8px;
  display: inline-block;
}

.entry__meta li:last-child:after {
  display: none;
}

.entry__meta a {
  color: #2d95e3;
}

.entry__meta a:hover {
  color: #171821;
}

.thumb__text__holder .entry__meta a,
.thumb__text__holder .entry__meta a:focus,
.thumb__text__holder .entry__meta a:hover,
.thumb__text__holder .entry__meta li,
.thumb__text__holder__2 .entry__meta a {
  color: #fff !important;
}

.entry__img__holder {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.entry__meta + .entry__img__holder {
  margin-top: 20px;
}

.entry__img__holder .entry__score {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
}

.thumb__text__holder {
  position: absolute;
  bottom: 15px;
  width: 100%;
  z-index: 3;
  padding: 0 24px;
  text-align: center;
}

.thumb__text__holder__1,
.thumb__text__holder__3,
.thumb__text__holder__4 {
  text-align: left;
}

.thumb__text__holder__1 .thumb__entry__title {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.thumb__text__holder__2 {
  text-align: center;
}

.thumb__text__holder__2 .thumb__entry__title {
  font-size: 1.2rem;
}

.thumb__text__holder__3 .thumb__entry__title {
  font-size: 1.2rem;
  margin-top: 16px;
}

.thumb__text__holder__4 .thumb__entry__title {
  font-size: 1.2rem;
}

.thumb__text__holder__5 {
  text-align: center;
  top: 50%;
  left: 50%;
  bottom: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.thumb__text__holder__5 .thumb__entry__title {
  font-size: 1.2rem;
  max-width: 770px;
  margin: 8px auto;
}

.thumb {
  margin-bottom: 0;
}

.thumb__mb__0 {
  margin: 0;
}

.thumb__mb__20 {
  margin-bottom: 20px;
}

.thumb__size__1,
.thumb__size__2 {
  height: 250px;
}

.thumb__size__3 {
  height: 380px;
  max-height: 464px;
}

.thumb__size__4 {
  height: 395px;
}

.thumb__size__5 {
  height: 521px;
}

.thumb__size__6 {
  height: 609px;
}

@media only screen and (max-width: 991px) {
  .thumb__size__6 {
    height: 440px;
  }
}

@media only screen and (max-width: 767px) {
  .thumb__text__holder__2 .thumb__entry__title,
  .thumb__text__holder__3 .thumb__entry__title,
  .thumb__text__holder__5 .thumb__entry__title {
    font-size: 1.2rem;
  }

  .thumb__size__1,
  .thumb__size__2 {
    height: 200px;
  }

  .thumb__size__3,
  .thumb__size__4,
  .thumb__size__5,
  .thumb__size__6 {
    height: 265px;
  }
}

.thumb__url {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  bottom: 0;
}

.thumb__entry__title {
  color: #fff;
  font-size: 1.2rem;
}

.thumb__entry__title a:focus,
.thumb__entry__title:hover a {
  color: #941b20;
}
.thumb__entry__title:focus,
.thumb__entry__title:hover {
  color: #941b20;
}

.thumb__entry__title-two {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0;
}

.thumb__entry__title-two a:focus,
.thumb__entry__title-two:hover a {
  color: #fff;
}

.thumb__50 {
  padding-bottom: 50%;
}

.thumb__60 {
  padding-bottom: 60%;
}

.thumb__65 {
  padding-bottom: 65%;
}

.thumb__70 {
  padding-bottom: 70%;
}

.thumb__75 {
  padding-bottom: 75%;
}

.thumb__80 {
  padding-bottom: 80%;
}

.thumb__100 {
  padding-bottom: 100%;
}

@media only screen and (min-width: 992px) {
  .nopadding {
    padding: 0;
  }

  .nopaddingleft {
    padding: 0 5px 0 0;
  }

  .nopaddingright {
    padding: 0 0 0 5px;
  }

  .nopaddingrightside {
    padding: 0 15px;
  }

  .thumb__size__1 {
    height: 182px;
    max-height: 182px;
    padding: 10px 0 0;
  }
}

.bottom__gradient {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  z-index: 1;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: rgba(0, 0, 0, 0.7);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.7)),
    to(transparent)
  );
  background: linear-gradient(to top, rgb(0 0 0 / 98%) 30%, transparent 100%);
}
